<template>
    <div class="bg-f">
        <!--公共头部-->
        <Header></Header>
        <!-- 用户中心头部 -->
        <user-header />
        <!-- 内容 -->
        <div class="main-content">
            <div class="container">
                <div class="accout-content clearfix" style="display:flex">
                    <!-- 左边 -->
                    <user-left mark="employee"  :userId="userId"/>
                    <!-- 右边 -->
                    <a-card :bordered="false" class="card-right">
                        <div class="first">
                            <span><i style="color:red">*</i>{{ $t('user.account') }}:</span>
                            <input type="text" v-model="account">
                        </div>
                        <div class="second">
                            <span><i style="color:red">*</i>{{ $t('user.info.last-name') }}:</span>
                            <input type="text" v-model="firstName">
                        </div>
                        <div class="second">
                            <span><i style="color:red">*</i>{{ $t('user.info.first-name') }}:</span>
                            <input type="text" v-model="lastName">
                        </div>
                        <div class="second">
                            <span><i style="color:red">*</i>{{ $t('user.info.tel') }}:</span>
                            <input type="text" v-model="phone">
                        </div>
                        <div class="second">
                            <span><i style="color:red">*</i>{{ $t('user.info.email') }}:</span>
                            <input type="text" v-model="email">
                        </div>
                        <div class="second">
                            <span><i style="color:red">*</i>{{ $t('user.info.position') }}:</span>
                            <input type="text" v-model="position">
                        </div>
                        <button class="submit" @click="regsub()">{{ $t('user.submit') }}</button>
                    </a-card>
                </div>
            </div>
        </div>
        <!-- 公共尾部 -->
        <Footer></Footer>
    </div>
</template>

<style>
    .main-content .card-right{
        width: 100%;
    }
    .first,.second{
        text-align: left;
    }
    .first>span,.second>span{
        font-size: 24px;
        width: 130px;
        display: inline-block;
    }
    input[type=text]{
      width: 300px;
      border: 1px solid #787878;
      margin-left: 10px;
    }
    .second{
        margin-top: 20px;
    }
    .submit{
        width: 200px;
        height: 40px;
        margin-top: 40px;
        border: 1px solid  #d9d9d9;
        line-height: 40px;
        background: #ff4d4f;
        color: #fff;
        font-size: 16px;
        border-radius: 4px;
    }
</style>

<script>
import UserHeader from './components/Header'
import UserLeft from './components/Left'
import Header from '../components/Header'
import Footer from '../components/Footer'
import Editor from '@/components/Editor/TinyMceEditor'

export default {
  name: 'EmplyAdd',
  components: {
    UserHeader,
    Footer,
    UserLeft,
    Editor,
    Header
  },
  data () {
    return {
        userId: this.$route.params.userId,
        account: '',
        firstName: '',
        lastName: '',
        phone: '',
        email: '',
        position: ''
    }
  },
   methods: {
    //子账号添加
    regsub() {
        // if (this.account == '' || this.firstName == '' || this.lastName == '' || this.phone == '' || this.email == '' || this.position == '') {
        //     this.$layer.alert("请正确填写信息");
        //     return 
        // }
        if (this.account == '') {
            this.$layer.alert("账号不能为空");
            return 
        } else if (this.firstName == '') {
            this.$layer.alert("姓不能为空");
            return 
        } else if (this.lastName == '') {
            this.$layer.alert("名不能为空");
            return 
        } else if (this.phone == '') {
            this.$layer.alert("电话不能为空");
            return 
        }  else if (this.email == '') {
            this.$layer.alert("邮箱不能为空");
            return 
        } else if (this.position == '') {
            this.$layer.alert("职位不能为空");
            return 
        } 
        const params = {
            account: this.account,
            firstName: this.firstName,
            lastName: this.lastName,
            phone: this.phone,
            email: this.email,
            position: this.position
        }
        this.$common.handlePost('/user/childs/add', params).then(data => {
        if (data.code === 200) {
            this.$layer.alert(data.msg);
            this.$router.push({name: 'UsersEmployee', params: {userId: this.userId}})
        } else {
            this.$layer.alert(data.msg);
        }
      }).catch(() => {
        this.$notification.error({
        message: '错误',
        description: '请求信息失败，请重试'
        })
      })
    }
   }
}
</script>
